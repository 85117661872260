<template>
  <div class="contacts">
    <header class="contacts-header">
      <div class="title">联系人</div>
      <!-- <div class="contacts-remind" @click="$store.commit('set', { type: 'remind', data: true })">
        <div class="remind-icon">
          <img src="../../../assets/image/icon-progress/icon_tx.png" />
        </div>
        <span>提醒</span>
      </div> -->
    </header>
    <main class="contacts-main">
      <div class="left-part">
        <header>
          <nav>
            <span :class="{ 'active-span': contactKey === '团队' }" @click="contactKey = '团队'">团队</span>
            <div class="dividing-line"></div>
            <span :class="{ 'active-span': contactKey === '群组' }" @click="contactKey = '群组'">群组</span>
          </nav>
          <div v-if="contactKey === '团队'">
            <div class="search">
              <input type="tel" placeholder="搜索成员..." v-model="memberKeyword" />
            </div>
            <div class="display-member-list" @click="ctrl = !ctrl">
              <img :src="openlisticon" alt="" v-show="ctrl" />
              <img :src="closelisticon" alt="" v-show="!ctrl" />
              <span v-if="memberKeyword === ''">全部联系人({{ member.length }}个）</span>
              <span v-else>查到联系人({{ resGroup.length }}个）</span>
            </div>
          </div>
          <div v-if="contactKey === '群组'">
            <div class="search">
              <input type="tel" placeholder="搜索群组..." v-model="groupKeyword" />
            </div>
            <div class="display-member-list" @click="ctrlgroup = !ctrlgroup">
              <img :src="openlisticon" alt="" v-show="ctrlgroup" />
              <img :src="closelisticon" alt="" v-show="!ctrlgroup" />
              <span v-if="memberKeyword === ''">全部联系人({{ group.length }}人）</span>
              <span v-else>查到联系人({{ resGroup.length }}人）</span>
            </div>
          </div>
        </header>
        <main v-if="contactKey === '团队'">
          <ul class="member-ul" v-show="ctrl" v-if="memberKeyword === ''">
            <li
              v-for="item in member"
              :key="item.id"
              @click="memberKey = item.id"
              @dblclick="toCommunicate(item)"
              :class="{ active: memberKey === item.id }"
            >
              <div class="head-draw head" v-if="item.name !== '' && item.avatar == defaultImg">{{ lastName(item.nickname) }}</div>
              <img class="head-draw head" :src="item.avatar" :alt="item.nickname" v-if="item.nickname !== '' && item.avatar != defaultImg"/>
              <span>{{ item.nickname }}</span>
            </li>
          </ul>
          <ul class="member-ul" v-show="ctrl" v-if="memberKeyword !== ''">
            <li
              v-for="item in resMember"
              :key="item.id"
              @click="memberKey = item.id"
              @dblclick="toCommunicate(item)"
              :class="{ active: memberKey === item.id }"
            >
              <!-- <div class="head-draw head">{{ lastName(item.nickname) }}</div> -->
              <img class="head-draw head" :src="item.avatar" :alt="item.name" />
              <span>{{ item.nickname }}</span>
            </li>
          </ul>
        </main>
        <main v-if="contactKey === '群组'">
          <ul class="member-ul" v-show="ctrl" v-if="groupKeyword === ''">
            <li
              v-for="item in group"
              :key="item.id"
              @click="groupKey = item.id"
              @dblclick="toCommunicate(item)"
              :class="{ active: groupKey === item.id }"
            >
              <div class="head-draw head">{{ lastName(item.group_name) }}</div>
              <span>{{ item.group_name }}</span
              ><span> ({{ item.count }})</span>
            </li>
          </ul>
          <ul class="member-ul" v-show="ctrl" v-if="groupKeyword !== ''">
            <li
              v-for="item in resGroup"
              :key="item.id"
              @click="groupKey = item.id"
              @dblclick="toCommunicate(item)"
              :class="{ active: groupKey === item.id }"
            >
              <div class="head-draw head">{{ lastName(item.group_name) }}</div>
              <span>{{ item.group_name }}</span>
            </li>
          </ul>
        </main>
      </div>
    </main>
  </div>
</template>

<script>
import { postChatwsTeam, postChatwsGroup, postChatwUserLis, postChatwGroupTofirst } from '@/service/help';
import cookie from '@/utils/cookie';
export default {
  name: 'Contacts',
  data() {
    return {
      defaultImg:'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png',
      contactKey: '团队',
      memberKeyword: '',
      groupKeyword: '',
      openlisticon: require('@/assets/image/progress/addTask/openlist.png'),
      closelisticon: require('@/assets/image/progress/addTask/closelist.png'),
      ctrl: true,
      ctrlgroup: true,
      member: [
        {
          id: 0,
          nickname: '',
          avatar: '',
        },
      ],
      group: [],
      memberKey: '',
      groupKey: '',
    };
  },
  created() {
    postChatwsTeam({
      keyword: '',
    }).then(res => {
      this.member = res.data.data;
    });
    postChatwsGroup({
      keyword: '',
    }).then(res => {
      this.group = res.data.data;
    });
  },
  methods: {
    toCommunicate(item) {
      //跳转到聊天
      if (typeof item.group_id === 'undefined') {
        //单聊
        if (item.id === cookie(document).get('uid')) {
          return;
        }
        let id = parseInt(item.id);
        postChatwUserLis({
          members: [id],
        }).then(() => {
          this.$store.commit('updateGroupStatus', '跳转');
          this.$router.push('/chat/communicate');
        });
      } else {
        postChatwGroupTofirst({
          //群聊
          group_id: item.group_id,
        }).then(() => {
          this.$store.commit('updateGroupStatus', '跳转');
          this.$router.push('/chat/communicate');
        });
      }
    },
    lastName(name) {
      return name.charAt(name.length - 2) + name.charAt(name.length - 1);
    },
  },
  computed: {
    resMember() {
      return this.member.filter(
        function (item) {
          this.memberKey = ''; //取消选中
          return item.nickname.indexOf(this.memberKeyword) >= 0;
        }.bind(this),
      );
    },
    resGroup() {
      return this.group.filter(
        function (item) {
          this.groupKey = ''; //取消选中
          return item.group_name.indexOf(this.groupKeyword) >= 0;
        }.bind(this),
      );
    },
  },
  watch: {
    '$route.path': {
      handler(val) {
        if (val === '/chat/contacts') {
          postChatwsGroup({
            keyword: '',
          }).then(res => {
            this.group = res.data.data;
          });
        }
      },
    },
  },
};
</script>

<style scoped lang="less">
@import '~@/assets/style/chat/contacts.less';
</style>
